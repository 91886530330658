import private_url from '../middleware/private-url';
import public_url from '../middleware/public-url';
import no_center_url from '../middleware/no-center-url';

const routes = [
    {
        path: "/",
        name: "live-preview",
        meta: { title: "Inicio", middleware: public_url },
        component: () => import("../views/live-preview/pages/landing.vue"),
    },
    {
        path: "/turns-management",
        name: "turns-management",
        meta: { title: "Gestión de turnos", middleware: private_url },
        component: () => import("../views/live-preview/turns-management/index.vue"),
    },
    {
        path: "/proceedings",
        name: "proceedings",
        meta: { title: "Expedientes", middleware: private_url },
        component: () => import("../views/live-preview/proceeding/index.vue"),
    },
    {
        path: "/proceeding/create",
        name: "proceeding-create",
        meta: { title: "Completar expediente", middleware: private_url },
        component: () => import("../views/live-preview/proceeding/create.vue"),
    },
    {
        path: "/proceeding/:id/edit",
        name: "proceeding-edit",
        meta: { title: "Editar expediente", middleware: private_url },
        component: () => import("../views/live-preview/proceeding/edit.vue"),
    },
    {
        path: "/proceeding/:id",
        name: "proceeding-details",
        meta: { title: "Expediente", middleware: private_url },
        component: () => import("../views/live-preview/proceeding/details.vue"),
    },
    {
        path: "/proceeding/:id/stage/:stageId",
        name: "proceeding stage",
        meta: { title: "Etapa", middleware: private_url },
        component: () => import("../views/live-preview/proceeding/stage.vue"),
    },
    //solicitud interna
    {
        path: "/applications",
        name: "applications-list",
        meta: { title: "Solicitudes", middleware: private_url },
        component: () => import("../views/live-preview/applications/index.vue"),
    },
    {
        path: "/applications/create",
        name: "applications-create",
        meta: { title: "Completar solicitud", middleware: private_url },
        component: () => import("../views/live-preview/applications/create.vue"),
    },
    {
        path: "/applications/:id/edit",
        name: "applications-edit",
        meta: { title: "Editar solicitud", middleware: private_url },
        component: () => import("../views/live-preview/applications/edit.vue"),
    },
    //solicitud externa
    {
        path: "/external/applications/edit/",
        name: "applications-edit-hash",
        meta: { title: "Completar formulario" },
        component: () => import("../views/live-preview/applications/external/edit.vue"),
    },
    {
        path: "/consultations",
        name: "consultations-list",
        meta: { title: "Consultas", middleware: private_url },
        component: () => import("../views/live-preview/consultation/index.vue"),
    },
    //clientes
    {
        path: "/clients",
        name: "clients-list",
        meta: { title: "Clientes", middleware: private_url },
        component: () => import("../views/live-preview/client/index.vue"),
    },
    {
        path: "/clients/create",
        name: "clients-create",
        meta: { title: "Crear cliente", middleware: private_url },
        component: () => import("../views/live-preview/client/create.vue"),
    },
    {
        path: "/clients/:id/edit",
        name: "clients-edit",
        meta: { title: "Editar cliente", middleware: private_url },
        component: () => import("../views/live-preview/client/edit.vue"),
    },
    //sessions
    {
        path: "/sessions/create",
        name: "sessions-create",
        meta: { title: "Crear Sesion", middleware: private_url },
        component: () => import("../views/live-preview/session/create.vue"),
    },
    {
        path: "/sessions/:id/edit",
        name: "sessions-edit",
        meta: { title: "Editar Sesion", middleware: private_url },
        component: () => import("../views/live-preview/session/edit.vue"),
    },
    //contacts
    {
        path:"/contacts",
        name: "contacts-list",
        meta:{ title: "Contactos", middleware: private_url },
        component: () => import("../views/live-preview/contacts/index.vue"),
    },
    {
        path:"/contacts/create",
        name: "contacts-create",
        meta:{ title: "Crear contacto", middleware: private_url },
        component: () => import("../views/live-preview/contacts/edit.vue"),
    },
    {
        path:"/contacts/:id/edit",
        name: "contacts-edit",
        meta:{ title: "Editar contacto", middleware: private_url },
        component: () => import("../views/live-preview/contacts/edit.vue"),
    },
    //billing
    {
        path: "/billing",
        name: "billing",
        meta: { title: "Facturación", middleware: private_url },
        component: () => import("../views/live-preview/billing/index.vue"),
    },
    //reports
    {
        path: "/management-reports",
        name: "management-reports",
        meta: { title: "Reportes de gestión", middleware: private_url },
        component: () => import("../views/live-preview/management-reports/index.vue"),
    },
    //auth
    {
        path: "/register-center",
        name: "register-center",
        meta: { title: "Crear centro de mediación", middleware: no_center_url },
        component: () => import("../views/live-preview/register-center/index.vue"),
    },
    {
        path: "/activate-account",
        name: "activate-account",
        meta: { title: "Activar cuenta", middleware: public_url },
        component: () => import("../views/live-preview/pages/auth/activate-account.vue"),
    },
    {
        path: "/login",
        name: "login",
        meta: { title: "Ingresar", middleware: public_url, },
        component: () => import("../views/live-preview/pages/auth/login.vue"),
    },
    {
        path: "/redirect",
        name: "redirect",
        meta: { title: "Redireccionando", middleware: public_url, },
        component: () => import("../views/live-preview/pages/maintenance/redirect.vue"),
    },
    //turns
    {
        path: "/turns/:id",
        name: "turns",
        meta: { title: "Detalles del proceso", middleware: private_url },
        component: () => import("../views/live-preview/turns/index.vue"),
    },
    //dashboard
    {
        path: "/dashboard",
        name: "dashboard",
        meta: { title: "Home", middleware: private_url },
        component: () => import("../views/live-preview/dashboard/dashboard.vue"),
    },
    //documents
    {
        path: "/documents",
        name: "documents-dashboard",
        meta: { title: "Documentos Home", middleware: private_url },
        component: () => import("../views/live-preview/documents-templates/dashboard.vue"),
    },
    {
        path: "/documents/create",
        name: "documents-create",
        meta: { title: "Crear documentos", middleware: private_url },
        component: () => import("../views/live-preview/documents-templates/documents/create-edit.vue"),
    },
    {
        path: "/documents/:id/edit",
        name: "documents-edit",
        meta: { title: "Editar documentos", middleware: private_url },
        component: () => import("../views/live-preview/documents-templates/documents/create-edit.vue"),
    },
    //external documents
    {
        path: "/external/documents/:hash/",
        name: "documents-external-view",
        meta: { title: "Crear documentos", middleware: public_url },
        component: () => import("../views/live-preview/documents-templates/documents/external-view.vue"),
    },
    {
        path: "/register",
        name: "register",
        meta: { title: "Crear una cuenta", middleware: public_url },
        component: () => import("../views/live-preview/pages/auth/register.vue"),
    },
    //consulta externa
    {
        path: "/external/consult",
        name: "external-consult",
        meta: { title: "Completar formulario" },
        component: () => import("../views/live-preview/consultation/create.vue"),
    },
    //privacy policies
    {
        path: "/privacy-policies",
        name: "provacy-policies",
        meta: { title: "Políticas de privacidad" },
        component: () => import("../views/live-preview/pages/landing/privacy-policies.vue"),
    },
    //terms and conditions
    {
        path: "/terms-and-conditions",
        name: "terms-and-conditions",
        meta: { title: "Terminos y condiciones" },
        component: () => import("../views/live-preview/pages/landing/terms-and-conditions.vue"),
    },
    {
        path: "/terms-and-conditions-preview",
        name: "terms-and-conditions-preview",
        meta: { title: "Preview TyC" },
        component: () => import("../views/live-preview/pages/landing/terms-and-conditions-preview.vue"),
    },
    //about us
    {
        path: "/about-us",
        name: "about-us",
        meta: { title: "Sobre nosotros" },
        component: () => import("../views/live-preview/pages/landing/about-us.vue"),
    },

    // Widget
    {
        path: "/chat",
        name: "chat",
        meta: { title: "Chat" },
        component: () => import("../views/live-preview/application/chat.vue"),
    },
    {
        path: "/mail",
        name: "mail",
        meta: { title: "Mail" },
        component: () => import("../views/live-preview/application/mail.vue"),
    },
    // Profile
    {
        path: "/account-profile",
        name: "account-profile",
        meta: { title: "Perfil", middleware: private_url },
        component: () => import("../views/live-preview/application/users/account-profile.vue"),
    },
    // maintanance
    {
        path: "/:pathMatch(.*)*",
        name: "error-404",
        meta: { title: "404" },
        component: () => import("../views/live-preview/pages/maintenance/error-404.vue"),
    },
    {
        path: "/under-construction",
        name: "under-construction",
        meta: { title: "Under Construction" },
        component: () => import("../views/live-preview/pages/maintenance/under-construction.vue"),
    },
    {
        path: "/comming-soon",
        name: "comming-soon",
        meta: { title: "Comming Soon" },
        component: () => import("../views/live-preview/pages/maintenance/comming-soon.vue"),
    },
    //users
    {
        path: "/users",
        name: "users-list",
        meta: { title: "Usuarios", middleware: private_url },
        component: () => import("../views/live-preview/users/index.vue"),
    },
    {
        path: "/users/create",
        name: "users-create",
        meta: { title: "Crear usuario", middleware: private_url },
        component: () => import("../views/live-preview/users/create.vue"),
    },
    {
        path: "/users/:id/edit",
        name: "users-edit",
        meta: { title: "Editar usuario", middleware: private_url },
        component: () => import("../views/live-preview/users/edit.vue"),
    },
]

if (process?.env?.VUE_APP_MODE === 'local') {
    routes.push({
        path: "/components",
        name: "components",
        meta: { title: "Comming Soon" },
        component: () => import("../views/live-preview/pages/maintenance/components.vue"),
    })
    routes.push({
        path: "/dev",
        name: "dev",
        meta: { title: "dev" },
        component: () => import("../views/live-preview/pages/maintenance/test.vue"),
    })
}

export default routes